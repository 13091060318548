<template>
    <div class="o-auth o-flexible-module">
        <div class="o-auth__summary-card">
            <div class="o-auth__title">
                <h1 class="text-4xl">Your Holgates Account</h1>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="o-auth__signposts">
                        <AccountCardsLoginCard
                            v-for="(signPost, index) in signPosts"
                            :key="index"
                            :title="signPost.title"
                            :description="signPost.description"
                            :to="signPost.to"
                            :icon="signPost.icon"
                            :bg="signPost.bg"
                            :button="signPost.button" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const signPosts = ref([
        {
            title: "Holiday Account Portal",
            description: "Manage your holiday bookings and account preferences.",
            to: "/account-login",
            bg: "#FEE8BD",
            button: "Visit Holiday Account Portal",
        },
        {
            title: "Owner’s Account Portal ",
            description: "Manage your account, request maintenance, amend personal details.",
            to: "https://owners.holgates.co.uk/",
            bg: "#B9E0DB",
            button: "Visit the Owner’s Portal",
        },
    ]);
</script>
